import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/Layout/Layout"
import PageHeader from '../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../components/Layout/Section/SectionPageMargin'
import Heading2 from '../components/Type/Heading2'
import Paragraph1 from '../components/Type/Paragraph1'
import Grid2 from '../components/Layout/Grid2/Grid2'
import GridItem from '../components/Layout/GridItem/GridItem'
import FlexCenter from '../components/Layout/Flex/FlexCenter'
import ImageBox from '../components/Layout/ImageBox/ImageBox'
import Img from "gatsby-image"
import SEO from "../components/Seo/Seo"

import styled from 'styled-components'
import ContactTracker from '../components/ContactTracker/ContactTracker'
import SocialLinksMiniBlack from '../components/SocialLinks/SocialLinksMiniBlack'
import ContactForm from '../components/Form/ContactForm'
//import Map from '../components/Map/map';
import SectionMargin from '../components/Layout/Section/SectionMargin'
import Heading3 from '../components/Type/Heading3'

const AboutPage = ({ location, data }) => 
  (
  <Layout>

    <SEO  title="About Summon" 
          description="We’ve created a better alternative for Australian’s to sell their cars."
          location={location}
    />

    <PageHeader 
      title="About Summon"
      text="For the longest time, prestige car owners have had two choices for selling their car; 
      sell to a Dealer (and get an ok deal) or sell privately (and put up with the hassle). 
      Finally there is a third choice, Summon does all the hard work for you, and provides 
      you with a great sale price and cash in your hand." 
      />

        <SectionPageMargin>
						<Grid2>
							<GridItem>
								<Heading2>Our mission</Heading2>
								<Paragraph1>
                  At Summon, our mission is to make it easier and fairer for Australian’s to sell their cars. 
                  Summon was borne out of our personal frustration with needing to sell a used car, and having to 
                  decide between two bad choices; trade-in with a dealer (and get a low price), or sell privately 
                  (which as busy people with young families is too time consuming). We asked ourselves, why wasn’t 
                  there a better way?
								</Paragraph1>
							</GridItem>
							<GridItem>
								<FlexCenter>
									<ImageBox>
										<Img fluid={data.missionImage.childImageSharp.fluid} />
									</ImageBox>
								</FlexCenter>
							</GridItem>
						</Grid2>
					</SectionPageMargin>

          <SectionPageMargin>
						<Grid2>
							<GridItem>
								<Heading2>A better choice</Heading2>
								<Paragraph1>
                Enter Summon - we provide a better alternative to selling your car privately or selling to a dealership. 
                Summon is a concierge service for selling your prestige car - we follow the same process as if you were 
                selling the car yourself, but we use technology to make the process smoother, and our expertise to get the 
                best market value for your car.
								</Paragraph1>
							</GridItem>
							<GridItem>
								<FlexCenter>
									<ImageBox>
										<Img fluid={data.betterChoiceImage.childImageSharp.fluid} />
									</ImageBox>
								</FlexCenter>
							</GridItem>
						</Grid2>
					</SectionPageMargin>

          <SectionPageMargin>
						<Grid2>
							<GridItem>
								<Heading2>Looking ahead</Heading2>
								<Paragraph1>
                  In the future, our goal is to make our process and technology available to the public, so that people with 
                  any car (not just prestige cars) have the ability to sell their cars privately in a better way. For more 
                  about how the Summon process works, the costs and what is included, please visit our FAQ page.
								</Paragraph1>
                <Paragraph1>
                  We look forward to helping you sell your prestige car!
                </Paragraph1>
							</GridItem>
							<GridItem>
								<FlexCenter>
									<ImageBox>
										<Img fluid={data.lookingAheadImage.childImageSharp.fluid} />
									</ImageBox>
								</FlexCenter>
							</GridItem>
						</Grid2>
					</SectionPageMargin>

          
        <SectionMargin bgColor="#f8f8f9" marginBottom="0px">
            <Grid2>
                <GridItem order="1">
                    <Heading3 >Get in touch</Heading3>
                    <ContactForm formAction="contact" />
                </GridItem>
                <GridItem order="2" >
                    <Heading3 >Contact Details</Heading3>
                    <Paragraph1>
                        Summon currently operates in Melbourne, Sydney, Gold Coast and Brisbane
                    </Paragraph1>
                    <table>
                        <tbody>
                            <tr>
                                <TH>Email</TH>
                                <td>
                                    <ContactTracker
                                        trackAction="Contact"
                                        trackLabel="Email Contact"
                                        hrefType="mailto:"
                                        hrefTo="hello@summon.com.au" >
                                        hello@summon.com.au
                                    </ContactTracker>
                                </td>
                            </tr>
                            <tr>
                                <TH>Phone</TH>
                                <td>
                                    <ContactTracker
                                        trackAction="Contact"
                                        trackLabel="Phone Contact"
                                        hrefType="tel:"
                                        hrefTo="0492786666" >
                                            0492 SUMMON (0492 786 666)
                                    </ContactTracker>
                                </td>
                            </tr>
                            <tr>
                                <TH>Social</TH>
                                <td><SocialLinksMiniBlack/></td>
                            </tr>
                        </tbody>
                        
                    </table>
                    {/* <Paragraph1>
                        <strong>Address</strong> (Melbourne) <br/>
                        787 High St, Armadale VIC 3143
                    </Paragraph1>
                    <MapContainer>
                        <Map />
                    </MapContainer> */}
                </GridItem>
            </Grid2>
        </SectionMargin>


  </Layout>
)

export default AboutPage
/*
      <h1>{data.allContentfulPage.edges[0].node.title}</h1>
      <Markdown source={data.allContentfulPage.edges[0].node.content.content} />
*/
export const query = graphql`
  query {
    allContentfulPage(filter: {contentful_id: {eq: "4jBl67KyCqcHnYVadwByNF"}}) {
      edges {
        node {
          content {
            content
          }
          title
        }
      }
    }

    missionImage: file(relativePath: { eq: "our-mission.png" }) {
      childImageSharp {
        fluid(maxWidth: 286) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    betterChoiceImage: file(relativePath: { eq: "better-choice.png" }) {
      childImageSharp {
        fluid(maxWidth: 286) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    lookingAheadImage: file(relativePath: { eq: "looking-ahead.png" }) {
      childImageSharp {
        fluid(maxWidth: 286) {
          ...GatsbyImageSharpFluid
        }
      }
    }

  }`

//   const MapContainer = styled.div`
//     height: 300px;
//     width: 300px;
//     margin-bottom: 20px;
// `
const TH = styled.th`
    width: 60px;
    padding: 10px 0px 10px 0px;
`
